@charset "UTF-8";
/*
#################### common ####################
*/
body {
    font-size: 1rem;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	 	 transition: background-color 5000s ease-in-out 0s;
		 -webkit-transition: background-color 9999s ease-out;
    	 -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
input:focus, textarea:focus {
    background-color: transparent!important;
    outline: 0!important;
    box-shadow: none!important;
}
.form-check-input:checked:focus[type="radio"] {
    background-color: #3B7DDD!important;
}

ol, ul, dl {
    padding: 0;

    li {
       list-style: none;
       padding: 0;
    }
}

// input type number
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

// select :: cf => https://blog.logrocket.com/creating-custom-select-dropdown-css/#adding-custom-arrow-native-select-dropdown
.custom-select {
    position: relative;

    &::before,
    &::after {
        --size: 0.3rem;
        content: "";
        position: absolute;
        right: 1.6rem;
        pointer-events: none;
    }

    &::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid black;
        top: 40%;
    }

    &::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid black;
        top: 55%;
    }
}
.custom-select select {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    padding: 0.6rem 0.8rem;
    margin: 0;
    border: 1px solid #ccc;
    box-shadow: none;
    width: 50%;

    &:hover {
        border-color: #888;
    }

    &:focus {
        border-color: #aaa;
        box-shadow: none;
        box-shadow: none -moz-mac-focusring;
        // color: #fff;
        outline: none;
    }

    &:disabled {
        opacity: 0.5;
    }
}

// order_new, 

.custom-wrap {
    justify-content: flex-end!important;

    .custom-select2 {
        
        display: block;
        padding: 0.5rem 0.85rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        border-radius: 0.2rem!important;
        border: 1px solid #dee2e6;
        width: auto;
        margin-right: 0.4rem;
        width: 60%;
    }
    .custom-button {
        width: 50%;
        padding: 0.5rem;
        cursor: pointer;
        border: 1px solid #ccc;
    }

    // 
    &.sendNum-wrap {

        display: flex;
        & .custom-select2 {
            width: 100%!important;
        }
    }
}

.regist-calling-num {
    padding: 0.4rem 1rem!important;
    width: 50%!important;
}

.upload-excel {
    padding: 0.4rem 1rem!important;
    width: 50%!important;
}

.card {
    margin-bottom: 0.8rem!important;
}

// label


// status label

// 임시저장 
.stat-saved {
	border: 1px solid #6c757d;
	color: #212529;
	background-color: transparent;
}

// 주문완료 
.stat-order-fin {
	border: 1px solid #17a2b8;
	color: #17a2b8;
	background-color: transparent;
}

// 정산확인 
.stat-confirm {
	border: 1px solid #3B7DDD;
	color: #3B7DDD;
	background-color: transparent;
}

// 발송대기 
.stat-send-wait {
	border: 1px solid #1cbb8c;
	color: #1cbb8c;
	background-color: transparent;
}

// 발송완료 
.stat-fin {
	border: 1px solid #1cbb8c;
	color: #fff;
	background-color: #1cbb8c;
}

// 발송취소 
.stat-canceled {
	border: 1px solid #6c757d;
	color: #fff;
	background-color: #6c757d;
}

// 발송실패
.stat-fail {
    border: 1px solid #dc3545;
	color: #fff;
	background-color: #dc3545;
}

// dev label
.json-used {
	border: 1px solid #3B7DDD;;
	color: #fff;
	background-color: #3B7DDD;
}

// text-overflow ellipsis

@mixin ellipsis-base {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
}

.ellipsis-90 {
    @include ellipsis-base;
    max-width: 90px;
}

.ellipsis-140 {
    @include ellipsis-base;
    max-width: 140px;
}

.ellipsis-180 {
    @include ellipsis-base;
    max-width: 180px;
}
.ellipsis-300 {
    @include ellipsis-base;
    max-width: 300px;
}

// pagination
@mixin pagin-arr-base {
    content: "";
    border: solid;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 0.25rem;
    vertical-align: 0;
}
.pagination-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    margin: 1.5rem 0 0.5rem;

    .pagination {
        border-radius: 0.2rem;
        position: relative;
        display: inline-flex;

        &__btn {
            border: 1px solid #ccc;
            color: #212529;
            background-color: transparent;
            padding: 0.4rem 0.8rem;

            &:hover {
                background-color: #efefef;
            }
            &.active {
                border: 1px solid #212529;
                background-color: #212529;
                color: #fff;
                &:hover {
                    background-color: #212529;
                }
            }
            
        }

        &__btn:first-child {
            border-top-left-radius: 0.4rem;
            border-bottom-left-radius: 0.4rem;
            padding-right: 0.5rem;

            &:after {
                @include pagin-arr-base;
                transform: rotate(135deg);
            }
        }
        &__btn:last-child {
            border-top-right-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
            padding-left: 0.5rem;

            &:after {
                @include pagin-arr-base;
                transform: rotate(315deg);
            }
        }
        &__btn:not(:first-child) {
            margin-left: -1px;
        }
        &__btn:first-child, &__btn:last-child {

        }
    }
}

// alert
.alert {
    position: fixed;
    bottom: 1%;
    right: 1%;
    width: 25%;
    margin-bottom: 1rem;
    border-radius: 0.2rem;
    
    color: #fff;
    display: flex;
    padding: 0;
    
    &__message {
        box-sizing: border-box;
        padding: 0.95rem;
        width: 100%;
    }
    
    & .btn-close {
        padding: 1.1875rem 0.95rem;
        position: absolute;
        right: 0;
        top: -4px;
        z-index: 2;
        cursor: pointer;
        color: #fff!important;
        background-image: none;

        &:focus:not(:focus-visible) {
            box-shadow: none;
        }
    }

    &.suc {
        background-color: #1c5da8;
    }
    &.ready {
        background-color: #fcb92c;
        color: #000;

        & .btn-close {
            color: #000;
        }
    }
    &.fail {
        background-color: #6c757d;
    }

}

// like fin effect modal
.like-modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 2rem;
    border-radius: 5px;
    z-index: 1000;

    &.show {
        display: block;
        animation: fadeInOut 3s ease;
    }

    &__cont {

        &__txt {
            font-size: 1.2rem;
        }
        &__svg {
            text-align: center;
            margin: 0.6rem 0;

            & svg {
                width: 4rem!important;
                height: 4rem!important;
            }
        }
    }
}

.heart-beat {
    -webkit-animation-name: heartBeat;
    animation-name: heartBeat;

    -webkit-animation-duration: calc(1s * 1.3);
    animation-duration: calc(1s * 1.3);

    -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
    animation-duration: calc(var(--animate-duration) * 1.3);

    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;

    -webkit-animation-delay: 1s;;
    animation-delay: 1s;

    animation: heartBeat 1.2s ease;
}

// deliveryCheckModal
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // 반투명 배경
    z-index: 999; // 다른 요소 위에 나타나도록
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: white;
    padding: 2rem 4rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); // 그림자 효과
    position: relative;
}

.btn-close {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;

    border: none;
    cursor: pointer;
    font-size: 16px;
}

// animate
@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  .animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-duration: 1s;
  }

  @keyframes heartBeat {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    14% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
  
    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    42% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
  
    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @-webkit-keyframes fadeOutDown {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
  @keyframes fadeOutDown {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
  .animate__fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
    animation-duration: 2s;
  }


  .heart-container {
    position: relative;

    svg {
        transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    }
    

    svg path {
        transition: transform 0.3s ease-in-out;
        transform-origin: center;
    }
  }
  
  .feather-heart path {
    transition: fill 0.3s ease-in-out;
  }
  
  @keyframes likeAnimation {
    0% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
    50% {
      transform: scale(1.3);
      -webkit-transform: scale(1.3);
    }
    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  
.liked {
    animation: likeAnimation 0.3s ease;
}


.min-w-80 {
    min-width: 80px!important;
}
.min-w-70 {
    min-width: 70px!important;
}
.min-w-50 {
    min-width: 50px!important;
}
.min-w-100 {
    min-width: 100px!important;
}

.auth-btn {

    position: relative;

    & .auth-tooltip {
        background-color: #222;
        color: #fff !important;
        text-align: left;
        padding: 1rem 1rem 0.6rem;
        border-radius: 0.4rem;
        position: absolute;
        z-index: 1;
        top: -0.8rem;
        left: 10rem;
        cursor: unset;

        &:after {
            content: " ";
            position: absolute;
            top: 0;
            left: -0.6rem;
            border-width: 0.8rem;
            border-style: solid;
            border-color: transparent transparent #222 transparent;
        }

        & h4 {
            color: #fff;
            font-size: 1rem;
            margin-bottom: 0.2rem;
        }
    }
}

.text-left { text-align: left!important; }
.text-right { text-align: right!important; }
.badge.badge-danger-light {
    background-color: rgba(220, 53, 69, .15);
    color: #dc3545;
}

.fz-08r { font-size: 0.8rem; }
.w-2r { width: 2rem; }

.hover-light-grey {
    &:hover {
        background-color: rgba(0,0,0,0.05);
    }
}

// slick-carousel 사용 ▼
.slick-prev,
.slick-next {
  font-size: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;

  &:before { color: #ccc; }
}

/*
#################### common ####################
*/



/*
#################### sidebar ####################
*/

[data-simplebar] {
    width: 220px;
}

.sidebar {
    min-width: 220px;
    max-width: 220px;

    &.collapsed {
        margin-left: -220px;
    } 
}

.sidebar-item.active > .sidebar-link, .sidebar-item.active .sidebar-link:hover {
    // border-left-color: #cf2e46;
}


/*
#################### footer regular ####################
*/
.main footer.footer {
    font-size: 14px;
}


/*
#################### main ####################
*/

.content {min-width: 640px;}
.content.main {
/*
    @media (min-width: 992px){
        padding: 1.4rem 1.6rem 1.4rem 2rem;
    }
*/



/*
        &.first-row {
            margin-bottom: 1rem;
        }

        .tbl-overflow {
            overflow: auto;
            width: 49.25%!important;
            padding: 0;
            border-radius: 1rem;
          
            &.end {
                margin-left: 1rem;
            }
            
            .card {
                padding: 0;
    
                & .card-header {
                    padding: 1.5rem 1rem 0.5rem;
                    
                    & .card-title {
                        font-size: 1.25rem;
                        color: #000;
                        padding: 0.5rem;
                    }
                }
    
                & table.table {
                    & thead {
                        & tr {
                            & th {

                                &:first-child {
                                    text-align: center;
                                }
                            }
                        }
                    }

                    & tbody {
                        & tr {
                            cursor: pointer;
                            &:last-child td {
                                border: 0;
                            }

                            & td {
                                &:first-child {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
    
            .tbl-minw-1100 {
                min-width: 600px !important;
                margin-bottom: 0!important;
            
                & tbody tr td:nth-child(3), & tbody tr td:nth-child(4) {
                    max-width: 148px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
              
            .tbl-minw-auto {
                min-width: 600px !important;
                margin-bottom: 0!important;
            }
    
    
        }
        & div > .card {

            padding: 1rem;
            font-size: 12px;
            padding-top: 0;

            & .card-header {

                padding-bottom: 0.6rem;
            }
            & table {

                & thead {

                    background-color: #eee;
                }
            }
        }
    }
*/
    // main direct-menu
    & .direct-menu {
        padding: 0;

        .card {
            
            & > span {
                font-size: 0.9rem;
            }

            & .card-body {

                & .grid-comp > div {
                    background-repeat: no-repeat;
                }
                & .grid-comp:nth-child(1) > div {
                    background-image: url('../img/direct-bg01.png');
                    background-position: 94% -30%;
                }
                & .grid-comp:nth-child(2) > div {
                    background-image: url('../img/direct-bg02.png');
                    background-position: 94% -8%;
                    background-size: 6rem;
                }
                & .grid-comp:nth-child(3) > div {
                    background-image: url('../img/direct-bg03.png');
                    background-position: 94% -50%;
                    background-size: 5rem;
                }
                & .grid-comp:nth-child(4) > div {
                    background-image: url('../img/direct-bg04.png');
                    background-position: 94% -60%;
                    background-size: 5rem;
                }
            }
        }
    }

    & .main-tbl {
        padding: 0;

        .card {
            min-width: 600px;

            & .card-header{

                padding-bottom: 0;
            }

            & table.table {

                & thead {

                    & tr {
                        text-align: center;

                        & th {
                            min-width: 52px;
                        }
                    }
                }

                & tbody tr {

                    text-align: center;
                    cursor: pointer;

                    & td:nth-child(3), & td:nth-child(4) {
                        max-width: 148px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                
            }
        }
        // 메인 테이블 중 가운데 정렬 필요한 tbody tr td = 이벤트명, 문의내용, 공지사항
        & .row {
            // 이벤트명
            & .col-12:nth-child(1) tbody tr td:nth-child(3) {
                text-align: left;
            }
            // 문의내용
            & .col-12:nth-child(2) tbody tr td:nth-child(3) {
                text-align: left;
            }
            // 공지사항
            & .col-12:nth-child(4) tbody tr td:nth-child(3) {
                text-align: left;
            }
        }
    }

    & .main-enterprise-tbl {

        font-size: 0.9rem;

        .card {

            & .card-header {
                padding-top: 28px;
            }

            & .card-body {
                padding: 0 1.25rem 1.25rem;
            }

            min-width: 100%;

            & table.table {

                & tbody tr {

                    & td:nth-child(2), & td:nth-child(3), & td:nth-child(4) {
                        max-width: 148px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        
        & .row {
            min-width: unset;

            &:first-child {

                & > div .card {
                    -webkit-overflow-scrolling: touch;
                    -ms-overflow-style: -ms-autohiding-scrollbar;
                }
                
                & > div:nth-child(1) .card {

                    overflow: auto;

                    & .card-body {
                        min-width: 512px;
                    }
                }
                & > div:nth-child(4) .card {

                    overflow: auto;

                    & .card-body {
                        min-width: 408.6px;
                    }
                }
            }

            &.direct {

                & .card-header {
                    padding: 2.5rem 1.25rem;
                    background-repeat: no-repeat;

                    @media (max-width: 1439px){
                        & a {
                            text-align: center;
                            display: block;
                        }
                    }
                }

                & > div:nth-child(1) .card-header {
                    background-position: 94% -30%;
                    background-image: url('../img/direct-bg01.png');
                }
                & > div:nth-child(2) .card-header {
                    background-image: url(../img/direct-bg02.png);
                    background-position: 94% -8%;
                    background-size: 6rem;
                }
                & > div:nth-child(3) .card-header {
                    background-image: url(../img/direct-bg03.png);
                    background-position: 94% -50%;
                    background-size: 5rem;
                }
                & > div:nth-child(4) .card-header {
                    background-image: url(../img/direct-bg04.png);
                    background-position: 94% -60%;
                    background-size: 5rem;
                }

            }

            @media (max-width: 767px) {
                & > div {
                    padding: 0;
                }
            }
        }
    }



    .main-row {
        min-width: 1230px;

        & .row-item {

            display: inline-block;

            & > .card {

                padding: 1.2rem;
                font-size: 12px;
                padding-top: 0;
                margin-right: 0.6rem;    

                & .card-header {
    
                    padding-bottom: 0.8rem;
                    padding-top: 1.4rem;

                    & svg {
                        margin-top: -0.25rem;
                    }

                    & .card-title {
                        color: #333;
                    }
                }
                & table {
    
                    & thead {
    
                        background-color: #eee;
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        & .main-row {
            min-width: unset;

            & .row-item {
                width: 100%;
            }
            
        }
    }
}

.main-sidebar {

    &  ul.sidebar-nav {

        & li.sidebar-item a.sidebar-link {

            display: flex!important;
            justify-content: space-between!important;
            
            & span+svg {
                display: flex;
                align-items: center;
                margin-top: 0.16rem;
            }
        }
    }
}

ul.main-goods {

    & li {
        
        margin: 0 0.5rem;

        & > a > img {
            width: 100% !important;
        }
        & > a > h4 {
            margin: 0.5rem 0;
            font-size: 0.75rem;
            text-align: center;
        }

    }
}






/*
#################### navbar ####################
*/

.navbar {

    &__menu {

        margin-left: 2rem;
        &__ul {
            display: flex;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            flex-direction: row;

            &__li {
                position: relative;

                &__a {
                    display: block;
                    padding: 0.5rem;
                    color: #495057;
                    background: none;
                    border: 0;
                    font-size: 1rem;

                    &::after {
                        display: inline-block;
                        margin-left: 0.255em;
                        margin-right: 1rem;
                        vertical-align: 0.1em;
                        content: "";
                        border-top: 0.3em solid;
                        border-right: 0.3em solid rgba(0,0,0,0);
                        border-bottom: 0;
                        border-left: 0.3em solid rgba(0,0,0,0);

                        padding: 0;
                        transform: unset;
                    }
                    &:hover {
                        color: rgba(0,0,0,0.9);
                    }
                }
                &.active > a {
                    font-weight: 800;
                }
                & .dropdown-menu {
                    display: none;
                    border: 0;
                    margin-top: 0;
                    border-radius: 0.375rem;
                    padding: 0;
                    position: absolute;
                    overflow: visible;
                    left: -0.5625rem;
                    top: 2.5rem;
                    /*
                    box-shadow: 0 -7px 9px 0 rgba(169, 184, 200, .15);
                    -webkit-box-shadow: 0 0.25rem 1rem 0rem rgba(169, 184, 200, 1);
                    -moz-box-shadow: 0 0.25rem 1rem 0rem rgba(169, 184, 200, 1);
                    */
                    font-size: 0.875px;
                    color: #495057;
                    border: 1px solid rgba(0, 0, 0, 0.125);
                    border-radius: 0.2rem;
                    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);

                    &__canvas {
                        border-radius: 0.375rem;
                        padding: 1rem 0;
                        z-index: 1000;

                        position: relative;
                        display: flex;
                        flex-direction: column;
                        color: #5e6e82;
                        
                        box-shadow: none;
                        background-color: #fff;
                        border: 0;

                        &::after {
                            content: "";
                            position: absolute;
                            z-index: -1;
                            width: 1rem;
                            height: 1rem;
                            background: inherit;
                            top: -0.5rem;
                            left: 1.8rem;
                            transform: rotate(45deg);
                            border-radius: 0.125rem;
                            border-width: 1px 0 0 1px;
                            border-style: solid;
                            border-color: rgba(0, 0, 0, 0.175);;
                            display: block;
                        }

                        &__item {
                            border: 0;
                            background-color: rgba(0,0,0,0);
                            white-space: nowrap;
                            text-align: inherit;
                            clear: both;
                            padding: 0.2rem 2.4rem;
                            margin: 0.3rem 0;
                            width: 100%;
                            display: block;
                            font-size: 1rem;
                            color: #495057;

                            &:hover {
                                background-color: #f9fafd;
                                color: #232e3c;
                                text-decoration: none;
                            }
                        }
                    }
                }

                &:hover .dropdown-menu {
                    display: block;
                }
                &:hover .dropdown-toggle {
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }
    }

    & .navbar-nav {
        & .nav-item {
            & .dropdown-menu {
                box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);
            }
        }
    }
}

/*
#################### direct button ####################
*/

.direct-wrap {
    position: relative;

    & button {
        border: 0;
        color: #fff;
        background-color: #1c5da8;
        position: fixed;
        bottom: 4%;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        right: 2%;
        box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);

        & svg {
            width: 2rem;
            height: 2rem;
            stroke-width: 0.16rem;
        }
    }

    & .direct-menu-wrap {
        display: none;
        position: fixed;
        bottom: 7rem;
        right: 2rem;

        &.show {
            display: block;
        }
        
        & .direct-menu {
            background-color: #fff;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            font-size: 14px;

            &__item {
                text-align: center;
                display: flex;
                width: 6rem;
                height: 6rem;
                align-items: center;
                justify-content: center;
                border: 1px solid rgba(0,0,0,0.125);
                border-bottom: 0;
                color: #495057;
                flex-direction: column;

                &:hover {
                    background-color: #f9fafd;
                    color: #232e3c;
                    text-decoration: none;
                    border-bottom: 1px solid rgba(0,0,0,0.125);
                    // color: #495057;
                    // border: 1px solid #495057;

                }
                &:hover + a {
                    border-top: 0;
                }
                &:last-child {
                    border-bottom: 1px solid rgba(0,0,0,0.125);
                    &:hover {
                        // border: 1px solid #495057;
                        background-color: #f9fafd;
                        color: #232e3c;
                    }
                }

                & svg {
                    display: block;
                    width: 2rem;
                    height: 2rem;
                    stroke-width: 1;
                    color: #777;
                }
                & > p {
                    margin: 0.25rem 0 0;
                }
            }
        }
    }
}



/*
#################### adminIcon ####################
*/
// .sidebar-item.active > .sidebar-link
.adminIcon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    left: 36px;
    background-color: #222E3C;
    border-radius: 50%;

    & svg {
        width: 100%;
        height: 100%;
        margin-right: 0 !important;
        margin-top: -6px;
    }
}



/*
#################### login ####################
*/

.container.loginpage {
    font-size: 14px;

    & .row {
        align-items: end;

        & .d-table {
            height: 80%;

            .bd-radius-1r {
                border-radius: 0.6rem;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                margin: 0;
    
                & .label {
                    text-align: center;
                    padding: 2rem;
                    border-top-left-radius: 0.6rem;
                    border-top-right-radius: 0.6rem;
                    position: relative;
    
                    &__bg-color {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to right, #b93452, #445392, #445392, #445392, #445392);
                        z-index: 1;
                        left: 0;
                        border-top-left-radius: 0.6rem;
                        border-top-right-radius: 0.6rem;
                    }
                    
                    &__bg-ptn {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url('../img/logobg-100p.png');
                        background-repeat: no-repeat;
                        background-position-y: 65%;
                        z-index: 2;
                        left: 0;
                        border-top-left-radius: 0.6rem;
                        border-top-right-radius: 0.6rem;
                        opacity: 0.3;
                    }
    
                    &__sub, &__ttl {
                        margin: 0;
                        z-index: 4;
                        position: relative;
                    }
                    
                    &__sub {
                        color: #fff;
                        font-weight: 200;
                        font-size: 1rem;
                    }
                }
    
                & .card-body  {
                    padding: 1rem;
    
                    & .form-el {
    
                        & .btn-signin {
                            margin: 2rem auto;
                            width: 100%;
                
                            & a.btn {
                                display: block;
                                padding: 0.8rem;
                                background-color: #1c5da8;
                                border-radius: 0;
                                margin-bottom: 0.5rem;
                            }

                            &__account {

                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                & > div > a {
                                    font-size: 0.8rem!important;
                                    margin: 0 0.5rem;
                                    color: inherit!important;

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        & .form-control {
                            padding: 0.8rem;
                            border-radius: 0;
    
                            &:focus {
                                border-color: #dee2e6;
                                box-shadow: none;
                            }
                        }
                    }
    
                    & .guide {
                        text-align: center;
                    }
                }
            }
        }
        & .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.8rem;
            margin-bottom: 1rem;

            & img, &__mention {
                display: inline-block;
            }
            & img {
                width: 5rem;
                margin-right: 1rem;
            }
            &__mention {

                text-align: center;

                & p {
                    margin: 0!important;

                    & span {
                        margin-right: 0.4rem;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    &.signup {

        & .row .d-table {
            margin-top: 1rem;

            & .label {

                padding: 1.2rem;

                &__sub {
    
                    font-size: 1.2rem!important;
                    font-weight: 500!important;
                }
            }
    
            & .card-body {
    
                & .form-el {

                    & div.failMarking {
                        border: 1px solid red;
                        padding: 1rem 1rem 1.5rem;
                    }
    
                    & input.form-control {
                        padding: 0.6rem!important;
                    }

                    & .new-id {

                        & .new-id-wrap {

                            display: flex;
                            justify-content: space-between;

                            & input, & select, & button {

                                padding: 0.6rem!important;
                                width: 40%;
                                border: 1px solid #dee2e6;
                            }
                            & button {
                                width: 18%;
                            }
                        }
                    }

                    & .new-busi-num {

                        &__wrap {
                            display: flex;
                            justify-content: space-between;

                            & input, & button {

                                padding: 0.6rem!important;
                                border: 1px solid #dee2e6;
                            }

                            & input {

                                text-align: center;
                                &.busi-num-01 { width: 22%; }
                                &.busi-num-02 { width: 22%; }
                                &.busi-num-03 { width: 26%; }
                            }

                            & button {
                                width: 25%;
                            }

                        }
                    }

                    & .corp-or-indi {

                        &__wrap {
                            display: flex;
                            justify-content: center;
                        }
                    }

                    & .corp-num {

                        &__wrap {

                            display: flex;
                            justify-content: space-between;

                            & input {
                                width: 49%;
                                text-align: center;
                            }
                        }
                    }


                    & .buis-man-tel {

                        &__wrap {

                            display: flex;
                            justify-content: space-between;

                            & input {
                                width: 32%;
                            }
                        }
                    }

                    & .manager-tel {

                        &__wrap {

                            display: flex;
                            justify-content: space-between;

                            & input, & button {

                                padding: 0.6rem!important;
                                border: 1px solid #dee2e6;
                            }
                            & input {
                                width: 22%;
                                text-align: center;
                            }
                            & button {
                                width: 30%;
                            }
                        }
                    }
    
                    & .btn-signin {
                        display: flex;
                        justify-content: center;
                        align-items: center;
        
                        & a {
                            color: #fff;
                            width: 40%;
        
                            &.prev {
                                background-color: #6c757d!important;
                                margin-right: 0.2rem;
                            }
                            &.subm {
                                background-color: #1c5da8!important;
                                margin-left: 0.2rem;
                            }
                        }
                    }
                }
            }
        }

        & .row .footer {
            &__mention {

                margin: 2rem 0;
            }
        }
    }
}


/*
#################### order-general +send-management ####################
*/

.content.order-general, .content.order-ssg, .content.send-management, .content.popup-wrap, .content.goods-view {

    .card.od-gen {
        // overflow: auto;

        & > .card-header {
            padding: 2rem 1.25rem 0;

            & .row.od-gen-filter {
                border-top: 1px solid #ccc;
                margin: 1rem 0;
                border-bottom: 1px solid #ccc;
                // min-width: 1090px;

                & .col-12 {
                    @media (max-width:1414px) {
                        flex: 0 0 auto;
                        width: 100%;
                    }
                }

                & .card-body {
                    padding: 1rem;

                    & input {
                        border: 1px solid #ccc;
                        padding: 0.5rem 1rem;

                        &:focus {
                            outline: none;
                        }

                        &#startDateIp {
                            width: 46%;
                            margin-right: 1.2%;
                        }
                        
                        &#endDateIp {
                            width: 46%;
                            margin-left: 1.25%;
                        }
                    }
                    &.customer {
                        padding-top: 0.88rem;
                    }

                    &.customer select#customerIp, &.sendStatus select#sendStatus {
                        width: 100%;
                    }
                    &.sendTitle {
                        & button {
                            width: 30%;
                            padding: 0.5rem;
                            cursor: pointer;
                            border: 1px solid #ccc;
                        }
                        & > .col-6 {
                            margin-right: 0.25rem;
                        }
                    }
                }
            }
        }

        & > .card-body {
            & .tbl-wrap {
                overflow: auto;

                & .table {
                    font-size: 14px;
                    min-width: 1400px;
    
                    & thead {
                        & tr {
                            & th {
                                // 표제목 4음절
                                &:not(:first-child) {
                                    min-width: 73px;
                                }
                                // 2음절
                                &:nth-child(2) {
                                    min-width: 50px;
                                }
                                // 3음절
                                &:nth-child(3), &:nth-child(4), &:nth-child(5),&:nth-child(7) {
                                    min-width: 61px;
                                }
                                // 6음절
                                &:nth-child(13){
                                    min-width: 109px;
                                }
                                // 5음절
                                    &:nth-child(14){
                                    min-width: 85px;
                                }
                            }
                        }
                    }

                    &.order_real {
                        min-width: 1514px;
                    }
                }

                // row선택으로 상세조회화면 바로가기 하는경우 커서모양 변경
            & #accountMng {

                    & tbody > tr {

                        cursor: pointer;
                    }
                }

                & #noticeRegister, & #inquiry, & #documentPage {

                    min-width: 1000px;
                    & tbody > tr {
                        cursor: pointer;
                    }

                    & tr th, & tr td {

                        text-align: center;
                    }

                }
            }
        }
    }
    select.select-manager {
        border: 1px solid #e9ecef;
        padding: 0.37rem;

        &:focus {
            border: 1px solid #e9ecef;
            outline: none;
        }
    }
}

.send-management {
    
    & tbody tr {
        cursor: pointer;
    }
}



/*
#################### order-new ####################
*/

.content.order-new, .content.send-management-detail {

    & .container-fluid {

        & .od-new {
            min-width: 1100px;

            // LEFT Setting Card
            & .left-area {

                & .card {

                    & .card-body.regist, & .card-body.ssg_new {
                        padding: 2rem 1.25rem 0;

                        & .col-6 {

                            & .row {

                                & .col-6, & .col-4, & .col-8 {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                    margin-bottom: 1rem;

                                    & .click-obj {
                                        & input, & div {
                                            cursor: pointer;
                                        }

                                        & select {
                                            width: 10rem;
                                            padding: 0.4rem 2rem;
                                            border: 1px solid #dee2e6;
                                            border-radius: 0.2rem;
                                            line-height: 1.5;
                                            font-size: 0.875rem;

                                            &:focus {
                                                outline: #dee2e6;
                                            }
                                        }
                                    }
                                }
                                
                                & .col-4 {
                                    padding-left: 0;
                                }

                                & .col-8.d-flex.justify-content-start {
                                    & .form-check {
                                        padding-left: 1.6rem;
                                    }
                                }

                                & .col-6:last-child {
                                    justify-content: left;
                                    padding-left: 0.5rem;
                                }

                                & .justify-content-end {
                                    & label:last-child {
                                        justify-content: left;
                                    }
                                }

                                & .write-set {
                                    padding-left: 2rem;

                                    &__ttl, &__cont {
                                        display: block;
                                        width: 100%;
                                        padding: 0.3rem 0.85rem;
                                        font-size: 0.875rem;
                                        font-weight: 400;
                                        line-height: 1.5;
                                        color: #495057;
                                        border-radius: 0.2rem;
                                        border: 1px solid #dee2e6;
                                    }
                                    &__ttl {
                                        margin: 0 1rem 0 0;
                                    }
                                    &__cont {
                                        height: 10rem;
                                    }
                                    &__length {
                                        font-size: 0.8rem;
                                        text-align: right;
                                        margin: 0.5rem 0 1rem;
                                    }
                                }
                            }
                        }

                        .col-6.left {
                            & .row {
                                & .col-9 {
                                    font-size: 14px;

                                    & label.discountApply {
                                        margin-right: 0;
                                        vertical-align: middle;
                                        display: flex;
                                        align-items: flex-start;

                                        & span {
                                            margin: 0 0.44rem;
                                        }

                                        & input[name="discountApplyInput"] {
                                            display: inline-block;
                                            width: 50%;
                                        }                                        
                                    }
                                }
                            }
                        }

                        & button {
                            width: auto;
                            padding: 0.5rem;
                            cursor: pointer;
                            border: 1px solid #ccc;
                            border-radius: 0.2rem;
                            padding: 0.4rem 0.6rem;
                        }

                        & .mms-guide {

                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            margin-bottom: 1rem;

                            & .btn {

                                width: 40%;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &.storage-box-btn {
                                    margin-left: 0.6rem;
                                    width: 26%;
                                    position: relative;
                                }
                                .dropdown {
                                    position: absolute;
                                    top: 2.5rem;
                                    left: 0;
                                    z-index: 999;

                                    &.dropdown-special-char {

                                        & .dropdown-menu {
                                            padding: 1rem;

                                            & a.dropdown-item {

                                                display: inline-block;
                                                width: auto;
                                                padding: 0.6rem 0.8rem;                                            
                                            }
                                        }
                                    }
                                }

                                &.special-char-btn, &.replace-char-btn {
                                    width: 32%;
                                    margin-left: 0.6rem;
                                    margin-bottom: 1rem;
                                    position: relative;
                                    & svg {
                                        margin-left: 0.2rem;
                                    }
                                }
                            }

                        }

                        .gift-card-type {

                            & .gct {

                                &__btn {
                                    border-start-start-radius: 0;
                                    border-end-start-radius: 0;
                                }
                            }
                        }

                        // 신세계주문 엑셀업로드 파일명
                        .upload-excel-name {
                            position: absolute;
                            top: 2.4rem;
                            font-size: 0.8rem;
                        }
                    }

                    // 발송관리 > 정산입력
                    & .card-body.settle-info {
                        padding: 0 1.25rem;
                        margin-bottom: 3rem;

                        & > div {
                            margin-bottom: 1rem;
                        }

                        & .col-6 {

                            & .row {

                                & .col-4 {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end; 
                                }
                                & .col-8 {

                                    & button {
                                        cursor: pointer;
                                        border: 1px solid #ccc;
                                        border-radius: 0.2rem;
                                        padding: 0.4rem 0.6rem;
                                        background-color: #fff;
                                        width: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 14px;
                                        color: #495057;

                                        & svg.feather {
                                            width: 14px;
                                            height: 14px;
                                            margin-left: 0.2rem;
                                        }

                                        &:hover {
                                            border: 1px solid #888!important;
                                            
                                            & * {
                                                color: #444!important;
                                            }
                                            & svg {
                                                transform: rotate(180deg);
                                                transition: 0.1s;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        & .settle-info__detail {
                            font-size: 14px;

                            & .dataTables_wrapper {

                                & .dt-row {

                                    & .settle-info-option {

                                        padding: 1rem 0;

                                        & > .col-3 {

                                            display: flex;
                                            align-items: center;
                                            justify-content: space-evenly;

                                            & select {
                                                padding: 0.24rem 0.1rem;
                                                border: 1px solid #dee2e6;
                                            }
                                            
                                            & input[type="radio"] {
                                                margin-left: 0.5rem;
                                            }
                                            & label {
                                                margin-left: 0.25rem;
                                            }
                                        }

                                        &__type {
                                            
                                        }

                                        &__deposit {

                                        }

                                        &__discount {

                                        }

                                        &__condition {

                                        }
                                    }

                                    & #settleTbl {

                                        & #settleTblBody {

                                            & td {
                                                & select[name="division"], & select[name="condition"] {
                                                    padding: 0.24rem 0.1rem;
                                                    border: 1px solid #dee2e6;

                                                    &:focus {
                                                        border: 1px solid #dee2e6;
                                                    }
                                                }
                                            }

                                            & [class ^="feePerc"] {
                                                width: 10%;

                                                & input[type="text"] {
                                                    width: 70%;
                                                    border: 1px solid #dee2e6;
                                                }
                                            }
                                        }
                                    }

                                    & .btn-group {

                                        & button {
                                            cursor: pointer;
                                            border: 1px solid #ccc;
                                            border-radius: 0.2rem;
                                            padding: 0.4rem 0.6rem;
                                            background-color: #fff;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 14px;
                                            color: #495057;

                                            &:hover {
                                                opacity: 0.86;
                                            }
                                        }
                                        & .btn-mod {
                                            & svg {
                                                width: 15px!important;
                                                height: 15px!important;
                                                stroke-width: 1px!important;
                                                margin-right: 0.25rem;
                                            }
                                        }

                                        &.mod-on {

                                            display: flex;
                                            align-items: center;
                                            justify-content: center;


                                            & [class ^="btn-"] {
                                                width: 10%;

                                                &:last-child {
                                                    margin-left: 0.2rem;

                                                    &.btn-save {
                                                        color: #fff;
                                                        background-color: #495057;
                                                        border: 1px solid #495057;
                                                    }
                                                }
                                            }

                                            & .btn-mod {
                                                display: none;
                                            }

                                        }

                                        &.mod-off {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;

                                            & .btn-canc, & .btn-save {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // RIGHT Preview Card
            & .right-area {

                /*
                #couponPreview, #emailPreview, #colcAndButton {
                    min-width: 470px;
                }
                */

                & #couponPreview {

                    & .card-body {
                        padding: 2rem 1.25rem;
                    }

                    .coupon {
                        // width: 420px;
                        margin: 1rem auto;
                        border: 1px solid #ccc;

                        & .row {
                            margin: 0;
                        }
                        & .coupon__btm {
                            padding: 1rem;
                            font-size: 14px;
                        }

                        &__top {
                            border-bottom: 1px solid #ccc;

                            & img {
                                width: 100%;
                            }
                        }
                        
                        &__mid {
                            &__brand, &__goods {
                                text-align: center;
                            }

                            &__brand {
                                height: 20%;

                                & img {
                                    width: 100%;
                                    max-height: 40px;
                                }
                            }
                            &__goods {
                                height: 80%;

                                & img {
                                    width: 100%;
                                    // max-width: 150px;
                                }
                            }

                            &__brand p, &__goods p {
                                margin: 0;
                            }
                            
                            &__logo {
                                // border-left: 1px solid #ccc;

                                & img {
                                    width: 100%;
                                }
                            }

                            &__left {
                                height: 100%;
                            }
                            &__right {
                                border-left: 1px solid #ccc;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            
                            & .coupon__mid__barcode {
                                // width: 420px;
                                text-align: center;
                                border-top: 1px solid #ccc;
                                border-bottom: 1px dotted #ccc;

                                & img {
                                    padding: 1rem 0;
                                    width: 80%;
                                }
                            }
                        }
                        &__btm {
                            padding: 2rem 1rem 1rem;
                        }
                    }
                }

                & #emailPreview {

                    & .card-body {
                        padding: 2rem 1.25rem;
                    }

                    & .email {

                        padding: 0 1rem;

                        &__ttl {
                            font-weight: 400;
                        }

                        &__cont {
                            font-size: 14px;
                            font-weight: 400;
                        }
                        
                        &__coupon, &__useage {
                            padding: 0;
                        }

                        &__coupon {
                            padding: 0 1rem;

                            &__wrap {
                                border: 1px solid #ccc;
                            }

                            &__top {
                                border-bottom: 1px solid #ccc;

                                & img {
                                    width: 100%;
                                }
                            }
                            &__mid {
                                margin: 0;

                                & .col-6 {
                                    border-bottom: 1px solid #ccc;
                                }

                                &__brand {
                                    height: 20%;


                                    & img {
                                        width: 100%;
                                        max-width: 96px;
                                    }
                                }
                                &__goods {
                                    height: 80%;

                                    & img {
                                        width: 100%;
                                        max-width: 72px;
                                    }
                                }
                                &__right {
                                    border-left: 1px solid #ccc;
                                    display: flex;
                                    align-items: center;
                                }
                                &__logo {
                                    // border-left: 1px solid #ccc;

                                    & img {
                                        width: 100%;
                                    }
                                }
                                &__barcode {
                                    width: 194px;
                                    border-bottom: 1px dotted #ccc;

                                    & img {
                                        width: 100%;
                                        padding: 0.6rem 1.4rem;
                                    }
                                }
                            }
                            &__btm {
                                font-size: 10px!important;
                                margin: 0;
                                padding: 0.6rem 0.8rem 0;

                            }
                        }

                        &__useage {

                            & p {
                                margin: 0;
                            }
                            & textarea {
                                border: 0;
                                width: 100%;
                                font-size: 12px;
                                font-weight: 400;
                            }
                        }
                    }
                    
                }

                & #colcAndButton {
                    
                    & .card-body {
                        padding: 2rem 1.25rem;

                        & .row:not(.sendSummary) {
                            padding: 0 1rem;
                            font-size: 14px;
                        }
                    }

                    & .sendSummary {
                        margin-bottom: 1rem;

                        &__count {

                            &__unit {
                                font-weight: 700;
                            }
                        }
                        &__total {

                            &__unit, &__unit2, &__unit3, &__unit4 {
                                font-weight: 700;
                            }
                        }
                    }

                    & .btn {
                        width: 32%;
                        height: 3rem;
                        font-size: 0.85rem;
                        padding: 0.18rem;
                    }
                    
                }
            }

            /*
            #################### 계정관리 > 등록 ####################
            */
            & .account-regist {

                & .card {

                    & .card-body {

                        & .col-6 {

                            & .row {

                                margin-bottom: 1rem;

                                & > div:first-child {

                                    justify-content: end;
                                    display: grid;
                                    align-items: center;
                                }

                                & .ip-chk-btn {

                                    padding: 0 1rem !important;
                                    border: 1px solid #dee2e6;
                                    margin-left: 0.4rem;
                                }
                            }
                        }

                        & .col-6:nth-child(3) {

                            & .row {

                                & > div:last-child {

                                    & > div {
                                        align-items: center;

                                    }
                                }

                                & .charge-amount {
                                    padding: 0.2rem 1rem !important;
                                    border: 1px solid #dee2e6;
                                    margin-left: 0.4rem;
                                }
                            }
                        }

                        .account-regist-tbl {

                            position: relative;
                            padding-left: 8%;

                            table {
                                text-align: center;

                                td select {
                                    display: block;
                                    padding: 0.5rem 0.85rem;
                                    font-size: 0.875rem;
                                    font-weight: 400;
                                    line-height: 1.5;
                                    color: #495057;
                                    border-radius: 0.2rem;
                                    border: 1px solid #dee2e6;
                                    width: 100%;
                                }

                                & th:last-child {
                                    width: 10%;
                                }
                                & td:last-child {
                                    input {
                                        width: 70%;
                                        display: inline-block;
                                    }
                                }
                            }

                            &__add-btn {
                                padding: 0.6rem;
                                border: 1px solid #dee2e6;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: #fff;
                                color: #3ec59d;
                                opacity: .65;

                                position: absolute;
                                top: 1.2rem;
                                right: -5rem;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }

                        .regist-tbl {
                            text-align: center;
                            padding: 0 5%;

                            table {
                                thead {
                                    th:nth(9) {
                                        width: 10%;
                                    }
                                    th:last-child {
                                        width: 5%;
                                    }
                                }

                                tbody {
                                    tr td:last-child {

                                        svg {
                                            width: 1.5rem;
                                            height: 1.5rem;
                                            opacity: .65;
        
                                            &:hover {
                                                opacity: 1;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .account-regist-tbl-hr {
                            width: 90%;
                            margin: 0 auto;
                            margin-top: 2rem !important;
                        }

                        .regist-save-btn-wrap {
                            button {
                                width: 8%;
                                height: 2.5rem;
                                padding: 0.18rem;
                            }
                        }
                    }
                }
            }

            // 공지사항 등록
            .notice-regist {


            }
        }
    }
}

.content.ssg-order {

    .coupon__btm {
        border-top: 1px solid #ccc;
    }
}

.save-prev-btn-wrap {

    button {
        width: 8%;
        height: 2.5rem;
        padding: 0.18rem;
    }
}


// selected goods
.selected-goods {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 2.4rem;

    & .sg-wrap {
        max-height: 400px;
        overflow-y: auto;
        padding-right: 0;
        display: block;

        & .sg {
            display: flex;
            background: #fff;
            color: #495057;
            position: relative;
            box-sizing: border-box;
            border: 1px solid #ced4da;
            border-radius: 0.2rem;
            padding: 1rem 0.8rem 0.6rem;
    
            &.active {
                border: 2px solid #3B7DDD;
    
                & .act-bg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: #3b7ddd33;
                }
            }
    
            &__close {
                padding: 1.1875rem 0.95rem;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
                --bs-btn-close-focus-shadow: none;
            }
            &__img {
                padding: 1rem;
                padding-left: 0;
                padding-right: 0.6rem;
            }
            &__body {
                font-size: 0.9rem;
    
                & .goods-name, & .goods-prise, & .goods-sale-date {
                    font-weight: 700;
                }
    
                & .alert-heading {
                    font-size: 1rem;
                }
            }
        }
    }

    & .send-msg-tbl-wrap {

        padding-left: 4%;
    }

    .card-body {
        padding: 2rem 1.25rem;
    }

    
}

/*
#################### order-new sendDate, sendTime ####################
*/
.send-req {
    margin-bottom: 1rem;

    & > .col-4 {
        align-items: flex-start!important;
        margin-top: 0.5rem;
    }
    #sendDate, #sendTime {
        display: block;
        padding: 0.5rem 0.85rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        border-radius: 0.2rem;
        border: 1px solid #dee2e6;
        width: 100%;
    }
    #sendDate {
        margin-bottom: 0.5rem;
    }

    @media (min-width:396px) {
        .input-group {
            justify-content: flex-end;
        }
    }
}

/*
#################### Tbale basic #################### 
*/

.card.table-basic-card {
    .card-header {
        .row {

            .search-opt {}
            .search-date {
                
            }
            .search-btn {}
        }
    }

    .card-body {
        .basic-wrap {
            font-size: 14px;
            overflow: auto;
        }
    }
}

// min-width set

.min-set-1150 {
    min-width: 1150px!important;
}

.min-set-1200 {
    min-width: 1200px!important;
}

.min-set-1370 {
    min-width: 1370px!important;
}

.min-set-1000 {
    min-width: 1000px!important;
}

.over-auto {
    overflow: auto;
}


/*
#################### card-header ####################
*/

.card .card-header {

    & .od-gen-filter {

        & .radio-set {

            display: flex;

            & .col-9 {

                padding-left: 2rem;
             
                input.form-check-input {
                    padding: unset!important;
                }
            }
        }

        & .serach-set {
            
            & .col-10 {

                display: flex;
                padding: 0 0.72rem;

                & select.serach-set__customer {
                
                    border: 1px solid #ccc;
                    padding: 0.54rem 1rem;
                    margin-right: 0.5rem;
    
                }
                & button {
                    padding: 0 1rem;
                    border: 1px solid #ccc;
                    margin-left: 0.5rem;
                }

            }
        }
    }

    & .basic-set {
        padding: 2rem 0 2rem 4rem;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin: 1rem 0;

        & > [class^=col-] {
            padding: 0!important;
        }

        .input-wrap {

            &__date {
                border: 1px solid #dee2e6;
                padding: 0.25rem 1rem;
            }
        }

        .btn-group {
            .input-group .form-select {
                flex: 0.4;
            }
        }
    }

}


// tbale each

#tblRegisterLinkage, #tblRegisterSearch, #tblRegisterLinkedProd {

    min-width: 1080px;

    thead tr th, tbody tr td {
        text-align: center;
    }
}

.register-search-wrap {
    max-height: 10rem;

    #tblRegisterSearch {

        & th {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: #fff;
        }
        & tr.on {
            background-color: #1798b82e;
        }
    }
}

.prod-add-btn-wrap {

    text-align: center;
    margin: 1.5rem 0;

    & button.btn {

        color: #326abc;
        border: 1px solid #326abc !important;

        &.active {
            color: #fff;
            background-color: #326abc;
        }
    }
}
.prod-regist-btn-wrap {
    text-align: center;
    margin: 1.5rem 0;
    & button.btn {

        &__save {
            color: #326abc;
            border: 1px solid #326abc !important;
        }
        &__prev {
            color: #666;
            border: 1px solid #666 !important;
        }
    }
}

#tblRegisterLinkedProd {
    #registerLinkedProdBody {
        .regist-remove {
            color: #777;
            &:hover { color: #000;}
        }
    }
}

/*
#################### popup search ####################
*/
.content.goods-view {
    .container-fluid {

        .row {

            .card {

                .card-body.goods-view-card-body {

                    // padding: 1.25rem 1.25rem 2rem;
                    padding: 2% 8% 3% 2%;

                    [class^=col-] {

                        margin-bottom: 0.8rem;

                        .row {

                            & [class^=col-]:first-child, & [class^=col-]:nth-child(3), & [class^=col-]:nth-child(5) {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                            }

                            .unit {
                                margin-left: 0.5rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .preview-goods-img img {
                                width: 50%;
                            }

                            & select {
                                line-height: 1.5;
                                border: 1px solid #dee2e6;
                                padding: 3.4% 30%;
                                border-radius: 0.2rem;
                            }
                            .goods-img-wrap {
                                align-items: center;
                                justify-content: center;
                                display: flex;

                                & .img-desc {
                                    font-size: 0.8rem;
                                    margin-top: 0.2rem;
                                }

                                & button[name="goods-img"] {
                                    cursor: pointer;
                                    border: 1px solid #ccc;
                                    border-radius: 0.2rem;
                                    padding: 0.4rem 0;
                                }
                            }

                            & textarea {
                                width: 100%;
                                border: 1px solid #dee2e6;
                                border-radius: 0.2rem;
                                font-size: 14px;
                                color: #495057;
                                padding: 1rem;
                                height: 10rem;
                            }
                            
                        }

                    }

                    & .save-btn-wrap {

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        .save-btn {

                            padding: 0.8rem 1.6rem;
                            font-size: 1rem;
                            margin: 1rem 0 0;
                        }
                    }

                    .evt-date {
                        position: relative;
                        &:after {
                            content: "~";
                            position: absolute;
                            top: 0.2rem;
                            right: -0.34rem;
                        }
                    }

                }

                & #goodsViewTbody tr:hover {
                    cursor: pointer;
                }
            }
        }
    }
}



/*
#################### popup search ####################
*/
.content.popup-wrap {
    padding: 1.5rem 1rem 5rem;
    position: relative;

    & footer {

        .select-status-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 5rem;
        background-color: #222E3C;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 999;
        color: #fff;
    
        #selectStat {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
    
        button {
            padding: 0.5rem 1rem;
            color: #fff;
            border-radius: 0.25rem;
            margin-right: 1rem;
            background-color: transparent;
            border: 1px solid #fff;
            cursor: unset;
        }
        button.selected-fin {
            background-color: #007bff;
            border: 1px solid #007bff;
            cursor: pointer;
        }
        button#selectAll, button#selectReset {
            margin-right: 0.2rem;
            font-size: 0.8rem;
            cursor: pointer;
            &:hover {
                background-color: rgba(255,255,255,0.2);
            }
        }
        button#selectReset {
            padding: 0.5rem;
            margin-right: 0.6rem;
        }
    }
    &.regist-guide-wrap {
        padding-bottom: 24px;
    }

    .popup-ttl-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .new-brand-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.2rem 0.6rem;
            border-radius: 0.2rem;
            background-color: #3B7DDD;
            border: 0;

            & > span {
                color: #fff;
            }
        }
    }

    // 고객관리 cs팝업 테이블
    &.cs-wrap table * {
        font-size: 0.9rem;
        text-align: center;
    }
}
.row.popup {
    & .card.od-gen {

        & .card-header{
            padding: 0 1.25rem;

            & .row.od-gen-filter {
                padding: 1rem 0;
                border-top: 0;
                min-width: unset;
                margin-bottom: 0;

                & input.form-control {
                    display: inline-block;
                    width: 70%;
                    margin-left: 1rem;
                }
                & button {
                    border: 1px solid #ccc;
                    padding: 0.25rem 1rem;
                }

                & .row {
                    .col-6 {
                        padding: 0;
                    }
                }
            }
        }

        & .card-body{
            // 2024-03-22 팝업 탭 공통
            display: none;

            padding: 1rem 1.25rem;

            table.table {
                min-width: 728px;

                & thead {

                    & tr {

                        & th:not(:first-child) {
                            text-align: center;
                        }
                        & th {
                            padding-top: 0;
                        }
                    }
                }

                & tbody {

                    & tr {
                        // cursor: pointer;

                        & td:not(:first-child) {
                            text-align: center;
                        }
                    }
                    & img {
                        width: 5rem;
                    }
                }
            }
        }
        // 2024-03-22 팝업 탭 공통
        & .card-body.active{
            display: block;
        }

        // 2024-03-22 팝업 탭 공통
        & ul.nav-tabs {

            justify-content: space-around;

            & li.nav-item {
                width: 50%;
                text-align: center;
                background-color: transparent;
                --bs-nav-tabs-link-active-bg: transparent;
                & a.nav-link {
                    border-color: transparent!important;
                    color: #777;

                    &.active {
                        background-color: transparent!important;
                        color: #111;
                        font-weight: 700;
                    }
                    &:hover {
                        border-color: transparent!important;
                    }
                }
            }

        }
    }
    // 발신번호 사전등록제 안내
    & .regist-guide {
        
        & .card-header {
            font-size: 13px;
            & ul {

                margin-bottom: 0;
                padding: 1rem;
                background-color: #eee;
                & dl {
                    margin-bottom: 0;
                    & dd span {
                        color: #007bff;
                    }
                }
            }
        }
        & .card-body {
            padding-top: 0;

            &__ttl {
                border-bottom: 1px solid #ccc;
                margin-bottom: 1rem;

                & h3, & span {
                    display: inline-block!important;
                }
                & span {
                    font-size: 13px;
                }
            }

            &__cont {
                padding: 0 1.8rem;
                margin-bottom: 2rem;

                & > div {
                    padding: 0.6rem;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;

                    &:first-child {
                        border-left: none;
                        border-right: 1px solid #ccc;
                    }
                }

                & > .col-2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                & .calling-num-wrap {
                    

                    & span {
                        & > input, & > div {
                            display: inline-block;
                        }
                        & > input {
                            width: 25%;
                        }
                        &:nth-child(2) {
                            margin-left: 1rem;
                            font-size: 12px;
                        }
                        & .refresh-btn {
                            padding: 0.3rem 0.4rem;

                            & svg {
                                width: 0.8rem;
                            }
                        }
                    }

                    & .tooltip {
                        position: relative;

                        &__txt {
                            visibility: visible;
                            background-color: #444;
                            color: #fff;
                            text-align: left;
                            padding: 0.8rem;
                            border-radius: 6px;
                            position: absolute;
                            z-index: 1;
                            width: 50%;
                            top: 8px;
                            left: -50px;
                            font-size: 13px;

                            &:after {
                                content: " ";
                                position: absolute;
                                bottom: 100%;  /* At the top of the tooltip */
                                left: 50%;
                                margin-left: -5px;
                                border-width: 5px;
                                border-style: solid;
                                border-color: transparent transparent black transparent;
                            }
                        }
                    }
                }

                & .req-btn {
                    width: 25%;
                    margin: 1rem auto;
                    padding: 0.4rem 0.2rem;
                    border: none;
                    color: #fff;
                    cursor: unset;
                    border-radius: 0.1rem;

                    &.btn-on {
                        background-color: #007bff;
                        cursor: pointer;
                    }
                }

                &.how-tbl-wrap {

                    margin-bottom: 1rem;
                    & table.how-tbl {
                        font-size: 13px;

                        & tr {

                            & td {
                                border: 1px solid #ccc;

                                
                                &[rowspan="5"] {
                                    border-left: none;
                                    text-align: center;
                                }
                                &[rowspan="2"], &[colspan="2"] {
                                    text-align: center;
                                    color: #007bff;
                                    text-decoration: underline;
                                }
                                &:last-child {
                                    border-right: none;
                                }
                                & ul {
                                    margin: 0;

                                    & li:last-child {
                                        & span:first-child {
                                            color: #007bff;
                                            text-decoration: underline;
                                        }
                                        & span:last-child {
                                            font-size: 11.5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // mms storage box
    &.mms-storage-box {
        
        & .card {

            & .card-header {
                text-align: right;
                font-size: 1rem;

                & #mmsleng {
                    font-weight: 600;
                }
            }

            & .card-body {
                padding-top: 0;

                & .row .mms-obj {

                    & .card-body {
                        background-color: rgba(0,0,0,0.04);
                        border-start-start-radius: 0.4rem;
                        border-start-end-radius: 0.4rem;

                        & h5.card-title {
                            max-width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 0.9rem;
                        }
                        & p.card-text {
                            overflow: auto;
                            max-height: 200px;
                            border: 1px solid #ccc;
                            background-color: #fff;
                            padding: 0.6rem;
                            border-radius: 0.4rem;
                            font-size: 0.8rem;
                        }

                        &:hover {
                            // background-color: #fff;
                            box-shadow: 0 0 0.4rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .popup-btn-group {
        display: flex;
        align-items: center;
        justify-content: center;

        & a.btn {
            width: 15%;

            &.btn-primary {
                margin-left: 0.4rem;
            }
        }
    }
}



#goodsAddTable, #evtOpenTable, #likedEvtOpenTable, #allGoods, #likedGoods, #showDelivery {
    font-size: 14px;
}

// 이벤트 불러오기
#evtOpenTable {

    & tbody#evtTableBody {

        & tr {

            &:hover {
                background-color: rgba(0,0,0,0.04);
            }
            &.chkd-tr {
                background-color: rgba(59,195,221,0.15);
            }

        }
    }
}
.delivery-popup-wrap {

    padding: 1.5rem 1rem;

    #showDelivery {

        & .tbl-wrap {
            overflow: auto;
        }

        #showDeliveryTbl {
            min-width: 980px;

            tr {
                text-align: center;
            }
        }
        #showDeliveryTbl.detailMode {
            min-width: 990px;
        }
    }

    .delivery-detail-card {
        font-size: 14px;

        & .card-header {

            padding-bottom: 0;
        }

        .timeline {

            list-style-type: none;
            position: relative;
            padding-left: 2rem;

            &:before {
                background: #dee2e6;
                height: 100%;
                left: 9px;
                width: 2px;
                content: " ";
                display: inline-block;
                position: absolute;
                z-index: 1;
            }
            & .timeline-item {

                &:before {

                    content: " ";
                    display: inline-block;
                    position: absolute;
                    z-index: 1;
                    background: #fff;
                    border: 2px solid #ddd;
                    border-radius: 50%;
                    height: 20px;
                    left: 0;
                    width: 20px;
                }
                
                &.active {

                    &:before {
                        border: 3px solid #ccc;
                    }

                    & > strong {
                        position: relative;

                        &:after {
                            content: " ";
                            display: inline-block;
                            position: absolute;
                            z-index: 2;
                            background: #3b7ddd;
                            border-radius: 50%;
                            height: 10px;
                            left: -1.7rem;
                            width: 10px;
                            bottom: 3px;
                        }
                    }
                }
            }

        }

    }
}

// event open btn
footer.open-evt-footer {

    justify-content: end!important;
    & .evt-status-wrap {
        padding: 0.8rem;
        & button {
            margin-right: 0.4rem!important;

            &#openEvtCancel {

                &:hover {
                    background-color: rgba(255,255,255,0.1);
                    cursor: pointer;
                }
            }

            &#openEvt.btn-on {

                background-color: #007bff;
                border: 1px solid #007bff;
                cursor: pointer;
            }
        }
    }
}

// event open goods info modal
.modal-wrap {

    &.modal-on {
        width: 100%;
        background-color: rgba(0,0,0,0.4);
        position: fixed;
        top: 0%;
        left: 0;
        height: 100%;
        z-index: 9999;
    }
}
#goodsModal {

    &.modal-on {
        position: fixed;
        width: 90%;
        background-color: #fff;
        height: 74%;
        top: 10%;
        left: 5%;
        z-index: 99991;
    }

    & .modal-footer {

        display: flex;
        flex-wrap: wrap;

        & .total-info {
            flex: 0 0 auto;
            width: 66.66666667%;
            padding: 0 2rem;

            &__evt-code, &__evt-name, &__evt-price-total {

                
                & span[class^=evt-] {
                    font-weight: 700;
                }
            }
        }

        & .close-btn-wrap {
            flex: 0 0 auto;
            width: 33.33333333%;
            padding: 0 2rem;
            display: flex;
            align-items: center;
            text-align: right;

            & .close-btn{
                width: 100%;
            }
        }

        &__close {

            width: 50%;
        }
    }

    & .card-body {
        
        height: 280px;
        overflow: auto;
    }
}

